import { makeStyles } from "@material-ui/core";
import centerAreaBackground from "../../assets/images/center-area-backround.png";

const useStyles = makeStyles((theme) => ({
  notFoundPage: {
    flex: 1,
    display: "flex",
    flexDirection: "column",
  },
  lineIcons: {
    position: "absolute",
    width: "100%",
    marginTop: "-15vh",
  },
  notFoundTextArea: {
    flex: 1,
    width: "100%",
    display: "flex",
    alignItems: "center",
    backgroundImage: `url(${centerAreaBackground})`,
    backgroundSize: "cover",
    marginBottom: "-10vh",
    paddingLeft: 122,
    paddingRight: 122,
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
      paddingLeft: 30,
      paddingRight: 30,
      paddingTop: "30vh",
    },
  },
  notFoundPageTextContainer: {
    marginLeft: 70,
    display: "flex",
    flexDirection: "column",
    width: "50%",
    [theme.breakpoints.down("sm")]: {
      marginLeft: 0,
      width: "100%",
      alignItems: "center",
    },
  },
  notFoundToyImage: {
    [theme.breakpoints.down("sm")]: {
      width: 110,
      height: 110,
    },
  },
  notFoundPageTitleText: {
    color: theme.palette.secondary.main,
    fontFamily: "Frenchpress, Roboto",
    letterSpacing: "4%",
    [theme.breakpoints.down("sm")]: {
      fontSize: theme.typography.h3.fontSize,
    },
  },
  notFoundPageSubtitleText: {
    fontWeight: 700,
    color: theme.palette.text.secondary,
    fontFamily: "Dosis, Roboto",
    marginTop: 30,
    [theme.breakpoints.down("sm")]: {
      textAlign: "center",
    },
  },
  initialPageButtonContainer: {
    marginTop: 30,
  },
  initialPageButton: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.text.primary,
    width: 189,
    "&:hover": {
      backgroundColor: theme.palette.primary.main,
    },
  },
}));

export default useStyles;
